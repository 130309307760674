import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";

import Button from "./Button";
import InputField from "./fields/InputField";
import ToggleSwitch from "Components/ToggleSwitch";
import Loading from "Components/Loading";
import ButtonWrapper from "Components/ButtonWrapper";
import InfoDialog from "Components/InfoDialog";

const Layout = styled.div`
  display: flex;
  flex-shrink: 0;
  margin: 0;
  &.add-form {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(152, 160, 171, 0.4);
    margin-bottom: 16px;
  }
  .description {
    font-size: 15px;
    color: #4a495e;
    margin-bottom: 24px;
    line-height: 20px;
    display: flex;
    align-items: flex-start;
    .info-dialog {
      margin: -2px 8px 0 -4px;
      min-width: 24px;
    }
  }
`;

const DomainFormLayout = styled.form`
  padding: 32px;
  min-height: 200px;
  width: 100%;
  .primary {
    margin-right: 10px;
  }
  textarea {
    width: 100%;
    box-sizing: border-box;
  }
`;

class DomainForm extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onIsDefaultChange = this.onIsDefaultChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.state = {
      ...this.props.domain,
      isDefault: this.props.isDefault,
      isChanged: false
    };
  }

  onChange(name, value) {
    this.setState({
      [name]: value,
      isChanged: true
    });
    this.props.onChange();
  }

  onNameChange(event) {
    this.onChange("name", event.target.value);
  }

  onIsDefaultChange() {
    const isChecked = this.state.isDefault;

    this.setState(prevState => ({
      isDefault: !prevState.isDefault,
      isChange: true
    }));

    this.onChange("isDefault", !isChecked || "");
  }

  onSave() {
    this.props.onSave(this.state);
  }

  onCancel() {
    this.props.onCancel();
  }

  render() {
    const { intl, name, isLoading, domain, onDelete, isNew } = this.props;
    const isExistingDomain = name ? true : false;

    return (
      <Layout className={isNew ? "add-form" : ""}>
        <LiveMessage
          message={
            name
              ? intl.formatMessage({ id: "edit_domain" })
              : intl.formatMessage({ id: "add_domain" })
          }
          aria-live="polite"
        />
        <DomainFormLayout
          id={isNew ? "domain-add-form" : "domain-edit-form"}
          aria-labelledby="add-domain-heading"
          onSubmit={event => event.preventDefault()}
        >
          <div className="description">
            <InfoDialog
              title="Domain name"
              text="Learn more about configuring your domain name."
              linkText="Learn more"
              to="https://docs.platform.sh/golive/steps.html#2-set-your-domain-in-platformsh"
            />
            <span>
              Enter a domain name for this project. Please do not contain www.
              if your <code>routes.yaml</code> file already includes it.
            </span>
          </div>
          <InputField
            label={intl.formatMessage({ id: "domain_name" })}
            id="domain-name-input"
            value={this.state.name || ""}
            onChange={this.onNameChange}
            error={this.props.errors.name}
            isDisabled={isExistingDomain}
          />
          <ToggleSwitch
            title={intl.formatMessage({ id: "is_default" })}
            description={intl.formatMessage({
              id: "is_default.description"
            })}
            onClick={this.onIsDefaultChange}
            value={this.state.isDefault}
            id="default-domain"
          />
          {isLoading ? (
            <Loading />
          ) : (
            (isNew ||
              this.state.isChanged ||
              (domain.hasLink && domain.hasLink("#delete"))) && (
              <ButtonWrapper className="actions">
                {(isNew || this.state.isChanged) && (
                  <React.Fragment>
                    <Button
                      readOnly={isExistingDomain}
                      id="save-domain-btn"
                      type="submit"
                      aria-label={
                        name
                          ? intl.formatMessage({ id: "save" })
                          : intl.formatMessage({ id: "add_domain" })
                      }
                      onClick={() => {
                        return this.state.name && this.onSave();
                      }}
                    >
                      {name
                        ? intl.formatMessage({ id: "save" })
                        : intl.formatMessage({ id: "add_domain" })}
                    </Button>
                    <Button
                      id="cancel-domain-btn"
                      type="button"
                      className="secondary"
                      aria-label={intl.formatMessage({ id: "cancel" })}
                      onClick={this.onCancel}
                    >
                      {intl.formatMessage({ id: "cancel" })}
                    </Button>
                  </React.Fragment>
                )}
                {domain.hasLink &&
                  domain.hasLink("#delete") && (
                    <Button
                      className="outline"
                      id={`project-domain-list-${(domain.name || "").replace(
                        /\./g,
                        ""
                      )}-delete-btn`}
                      type="button"
                      aria-label={intl.formatMessage({ id: "delete" })}
                      onClick={onDelete}
                    >
                      {intl.formatMessage({ id: "delete" })}
                    </Button>
                  )}
              </ButtonWrapper>
            )
          )}
        </DomainFormLayout>
      </Layout>
    );
  }
}

DomainForm.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  isDefault: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  errors: PropTypes.object,
  domain: PropTypes.object,
  intl: PropTypes.object,
  isNew: PropTypes.bool,
  onChange: PropTypes.func,
  index: PropTypes.number
};

export default injectIntl(DomainForm);
