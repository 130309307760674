import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Map } from "immutable";
import styled from "styled-components";
import { injectIntl, FormattedMessage } from "react-intl";
import { LiveMessage } from "react-aria-live";

import DomainForm from "Components/DomainForm";
import SettingLine from "Components/SettingLine";
import Heading2 from "Components/styleguide/Heading2";
import EmptyText from "Components/EmptyText";
import AddLink from "Components/AddLink";
import Loading from "Components/Loading";
import ModalConfirmDelete from "Components/ModalConfirmDelete";
import PageDescription from "Components/PageDescription";
import ModalConfirmLeaveForm from "Components/ModalConfirmLeaveForm";

const InfoLayout = styled.div`
  display: flex;
  align-items: center;

  span:first-of-type {
    width: 50%;
  }

  span:nth-of-type(2) {
    width: 20%;
  }
`;

const DomainWrapper = styled.div`
  p {
    margin-bottom: 30px;
  }
`;

class ProjectDomainListField extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.addNewDomain = this.addNewDomain.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.expand = this.expand.bind(this);
    this.closeExpand = this.closeExpand.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.delete = this.delete.bind(this);
    this.isChanged = this.isChanged.bind(this);
    this.state = {
      domains: [...(props.domains && props.domains.valueSeq().toJS())],
      isModalLeaveOpen: false,
      isChanged: false,
      changeIndex: false,
      index: ""
    };
  }

  componentDidMount() {
    this.props.loadDomains(this.props.project);
    this.setState(() => ({
      domains: [...this.props.domains.valueSeq().toJS()]
    }));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.project !== this.props.project) {
      this.props.loadDomains(nextProps.project);
    }
    if (nextProps.domains !== this.props.domains) {
      this.setState(() => ({
        domains: [...nextProps.domains.valueSeq().toJS()]
      }));
    }
  }

  onChange(value, field, index) {
    this.setState(prevState => {
      const nextState = prevState;

      nextState.isChanged = true;
      nextState.domains[index] = {
        ...(this.state.domains[index] || this.props.domains[index]),
        [field]: value
      };

      return nextState;
    });
  }

  save({ id, name, ssl, isDefault }) {
    // Make it so that that when an existing default domain is unchecked omit the default domain.
    const newDomain = { id, name, ssl };
    const projectSettings = {};
    const domains = this.props.domains;

    if (isDefault) {
      projectSettings.default_domain = name;
    } else if (this.props.project.default_domain === name) {
      // If the domain is the default but the user has unchecked
      // this, unset the default domain from the project.
      projectSettings.default_domain = "";
    }

    if (!domains.has(name)) {
      this.props.addDomain(this.props.project, newDomain);
    } else {
      this.props.updateDomain();
    }

    if (
      this.props.project.default_domain !== name ||
      projectSettings.default_domain === ""
    ) {
      this.props.updateProject(projectSettings);
    }
    this.setState({
      isChanged: false,
      changeIndex: false,
      index: "",
      isNew: false
    });
  }

  expand(index) {
    if (this.props.editedLine === index || this.props.isNew) {
      this.cancel();
    } else {
      if (this.state.isChanged && !this.state.isModalLeaveOpen) {
        this.setState({
          isModalLeaveOpen: true,
          isChanged: true,
          changeIndex: true,
          index: index
        });
      } else {
        this.setState({
          isChanged: false,
          changeIndex: false,
          index: index
        });
        this.props.editLine(index, false);
      }
    }
  }

  closeExpand() {
    this.cancel();
  }

  addNewDomain() {
    this.setState(prevState => {
      const nextState = prevState;
      if (!nextState.isNew) {
        nextState.isNew = true;
        nextState.domains.unshift({});
      }
      this.props.editLine(0, true);

      return nextState;
    });
  }

  cancel() {
    if (this.state.isChanged && !this.state.isModalLeaveOpen) {
      this.setState({
        isModalLeaveOpen: true,
        isChanged: true
      });
    } else {
      if (this.props.isNew) {
        this.setState(prevState => {
          const nextState = prevState;
          nextState.isNew = false;
          nextState.domains.splice(0, 1);
          return nextState;
        });
      }
      this.setState({
        isChanged: false,
        changeIndex: false,
        index: ""
      });
      this.props.cancelAddDomain();
    }
  }

  delete() {
    if (this.props.project.default_domain === this.state.domain.name) {
      this.props.updateProject({ default_domain: "" });
    }
    this.props.deleteDomain(this.state.domain);
  }

  openModal(domain) {
    this.setState({
      isModalOpen: true,
      domain
    });
  }

  closeModal() {
    this.setState({
      isModalOpen: false,
      isModalLeaveOpen: false
    });
  }

  isChanged() {
    this.setState({
      isChanged: true
    });
  }

  render() {
    if (!this.props.project) {
      return false;
    }
    const { isLoading, isUpdateLoading, intl } = this.props;
    return (
      <DomainWrapper>
        <LiveMessage
          message={`${this.props.project.title} domain settings`}
          aria-live="polite"
        />
        {this.props.project.hasPermission &&
          this.props.project.hasPermission("#manage-domains") && (
            <AddLink id="add-new-domain" onClick={this.addNewDomain} />
          )}
        <Heading2 id="settings-heading" style={{ marginBottom: "16px" }}>
          <FormattedMessage id="domains" />
        </Heading2>
        <PageDescription>
          A domain name is required to take your project live. Multiple domains
          can point at the same project and are routed based on your{" "}
          <code>routes.yaml</code> file.
        </PageDescription>
        <section aria-labelledby="settings-heading">
          {isLoading && <Loading />}
          {!isLoading &&
            this.state.domains.length === 0 && (
              <EmptyText>
                <FormattedMessage id="no_domains" />
              </EmptyText>
            )}
          {(this.state.domains || this.props.domains.valueSeq()).map(
            (domain, index) => {
              return (
                <div key={`${domain.id}-${index}-read`}>
                  <SettingLine
                    id={`project-domain-list-${(domain.name || "").replace(
                      /\./g,
                      ""
                    )}`}
                    info={
                      <InfoLayout>
                        <span>{domain.name}</span>
                        <span>{domain.updated_at}</span>
                      </InfoLayout>
                    }
                    isNew={!domain.id}
                    addNewTitle="Add domain"
                    isOpen={this.props.editedLine === index}
                    openText={intl.formatMessage({ id: "edit" })}
                    onClick={() => this.expand(index)}
                  >
                    {this.props.editedLine === index && (
                      <DomainForm
                        key={`${domain.id}-${index}-edit`}
                        domain={domain}
                        isNew={!domain.id}
                        onSave={this.save}
                        onCancel={this.cancel}
                        errors={this.props.errors}
                        name={domain.name}
                        isLoading={isUpdateLoading}
                        onDelete={() => this.openModal(domain)}
                        isDefault={
                          domain.name === this.props.project.default_domain
                        }
                        onChange={this.isChanged}
                        index={index}
                      />
                    )}
                  </SettingLine>
                </div>
              );
            }
          )}
          <ModalConfirmDelete
            isOpen={this.state.isModalOpen}
            closeModal={this.closeModal}
            deleteFunction={() => this.delete()}
            itemType="domain"
            itemName={this.state.domain && this.state.domain.name}
            itemId={(
              (this.state.domain && this.state.domain.name) ||
              ""
            ).replace(/\./g, "")}
          />
          <ModalConfirmLeaveForm
            isOpen={this.state.isModalLeaveOpen}
            closeModal={this.closeModal}
            continueFunction={
              this.state.changeIndex
                ? () => {
                    this.expand(this.state.index);
                  }
                : () => {
                    this.cancel();
                  }
            }
            cancelFunction={this.closeModal}
          />
        </section>
      </DomainWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  loadDomains: project =>
    import("Reducers/project/settings/domain").then(reducer =>
      dispatch(reducer.loadDomains(props.organizationId, project))
    ),
  addDomain: (project, domain, isDefault) =>
    import("Reducers/project/settings/domain").then(reducer =>
      dispatch(
        reducer.addDomain(props.organizationId, project, domain, isDefault)
      )
    ),
  updateDomain: () =>
    import("Reducers/project/settings/domain").then(reducer =>
      dispatch(reducer.updateDomain())
    ),
  deleteDomain: domain =>
    import("Reducers/project/settings/domain").then(reducer =>
      dispatch(
        reducer.deleteDomain(props.organizationId, props.projectId, domain)
      )
    ),
  editLine: (index, isNew) =>
    import("Reducers/project/settings/domain").then(reducer =>
      dispatch(reducer.editLine(index, isNew))
    ),
  cancelAddDomain: () =>
    import("Reducers/project/settings/domain").then(reducer =>
      dispatch(reducer.cancelAddDomain())
    ),
  updateProject: data =>
    import("Reducers/project/index").then(reducer =>
      dispatch(
        reducer.updateProject(props.organizationId, props.projectId, data)
      )
    )
});

const mapStateToProps = (state, props) => {
  const domain = state.projectDomain || new Map();
  const environment = state.environment || new Map();
  const project = state.project || new Map();

  return {
    domains: domain.getIn(
      ["data", props.organizationId, props.projectId],
      new Map()
    ),
    editedLine: domain.get("editedLine"),
    project: project.getIn(["data", props.organizationId, props.projectId]),
    isNew: domain.get("isNew"),
    errors: domain.get("errors", {}),
    environments: environment.getIn(
      ["data", props.organizationId, props.projectId],
      new Map()
    ),
    isLoading: domain.get("loading"),
    isUpdateLoading: domain.get("updateLoading")
  };
};

ProjectDomainListField.propTypes = {
  loadDomains: PropTypes.func,
  onChange: PropTypes.func,
  addDomain: PropTypes.func,
  editLine: PropTypes.func,
  cancelAddDomain: PropTypes.func,
  deleteDomain: PropTypes.func,
  project: PropTypes.object,
  errors: PropTypes.object,
  domains: PropTypes.object,
  environmentId: PropTypes.string,
  isNew: PropTypes.bool,
  isLoading: PropTypes.bool,
  isUpdateLoading: PropTypes.bool,
  editedLine: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  intl: PropTypes.object,
  updateProject: PropTypes.func,
  updateDomain: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ProjectDomainListField));
